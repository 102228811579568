define("discourse/plugins/discourse-new-features-feeds/discourse/routes/admin-plugins-discourse-new-features-feeds", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return (0, _ajax.ajax)("/admin/plugins/discourse-new-features-feeds.json");
    },
    actions: {
      triggerRefresh() {
        this.refresh();
      }
    }
  });
});