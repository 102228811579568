define("discourse/plugins/discourse-new-features-feeds/discourse/controllers/admin-plugins-discourse-new-features-feeds", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/mixins/scroll-top", "discourse-common/utils/decorators", "discourse/plugins/discourse-new-features-feeds/discourse/lib/new-features-form"], function (_exports, _controller, _object, _service, _scrollTop, _decorators, _newFeaturesForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FEATURE_TIERS = void 0;
  // Make sure to update NewFeatureFeedItem.tiers too!
  const FEATURE_TIERS = _exports.FEATURE_TIERS = [{
    id: 0,
    name: "standard"
  }, {
    id: 1,
    name: "business"
  }, {
    id: 2,
    name: "enterprise"
  }, {
    id: 3,
    name: "enhanced enterprise"
  }, {
    id: 4,
    name: "free"
  }, {
    id: 5,
    name: "basic"
  }, {
    value: 6,
    label: "starter"
  }, {
    value: 7,
    label: "probono"
  }];
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    availableTiers: FEATURE_TIERS,
    showCreateForm: false,
    activeTab: "general",
    currentUser: (0, _service.service)(),
    form: new _newFeaturesForm.default(),
    groupedNewFeatures(features) {
      const items = features.reduce((featuresAccumulator, feature) => {
        const key = moment(feature.released_at || feature.created_at).format("YYYY-MM");
        featuresAccumulator[key] = featuresAccumulator[key] || [];
        featuresAccumulator[key].push(feature);
        return featuresAccumulator;
      }, {});
      return Object.keys(items).map(date => {
        return {
          date: moment.tz(date, this.currentUser.user_option.timezone).format("MMMM YYYY"),
          features: items[date]
        };
      });
    },
    clearForm() {
      this.set("form", new _newFeaturesForm.default(this.currentUser));
    },
    afterSave() {
      this.set("showCreateForm", false);
      this.refresh();
    },
    editItem(item) {
      (0, _scrollTop.scrollTop)();
      this.setProperties({
        form: new _newFeaturesForm.default(this.currentUser, item),
        showCreateForm: true
      });
    },
    copyItem(item) {
      const newItem = new _newFeaturesForm.default(this.currentUser, item);
      newItem.id = null;
      newItem.tier = null;
      this.setProperties({
        form: new _newFeaturesForm.default(this.currentUser, item),
        showCreateForm: true
      });
    },
    toggleCreateForm() {
      this.clearForm();
      this.set("showCreateForm", true);
    },
    refresh() {
      this.send("triggerRefresh");
    }
  }, [["method", "groupedNewFeatures", [(0, _decorators.default)("model.new_features")]], ["method", "clearForm", [_decorators.bind]], ["method", "afterSave", [_object.action]], ["method", "editItem", [_object.action]], ["method", "copyItem", [_object.action]], ["method", "toggleCreateForm", [_object.action]], ["method", "refresh", [_object.action]]]));
});